import React from "react"
import { graphql} from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleList from "../components/article-list"

const Index = ({ data }) => (
  <Layout>
    <SEO title="akinul.com" keywords={[`akinul.com`, `Akinul Islam Jony`, `programming`, `tech`, `article`, `tutorial`,]} />
    <div>
      <ArticleList data={ data }/>
    </div>
  </Layout>
)

export const query = graphql`
	query ArticleListQuery {
		allMarkdownRemark(sort: { order: DESC, fields: frontmatter___publish }) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
            publish(formatString: "MMM DD, YYYY")
            update(formatString: "MMM DD, YYYY")
						path
            category
            keywords
					}
				}
			}
		}
	}
`;

export default Index

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony frontmatter